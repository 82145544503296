/* eslint-disable no-useless-computed-key */
import type {} from '@mui/material/themeCssVarsAugmentation';
import { alpha, PaletteColorChannel, extendTheme } from '@mui/material/styles';

// -----------
// Types
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ['in-progress']: true;
    icon: true;
    cancel: true;
  }
  interface ButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    quinary: true;
    info: false;
    error: true;
    warning: false;
    success: false;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    standard: true;
    inner: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    status: true;
  }
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    standard: true;
    inner: true;
  }
}

export const QuickColorOptions = [
  'sunrise.text-primary',
  'sunrise.text-secondary',
  'sunrise.text-tertiary',
  'sunrise.text-disabled',
  'sunrise.primary-main',
  'sunrise.primary-active',
  'sunrise.primary-onMain',
  'sunrise.secondary-main',
  'sunrise.secondary-active',
  'sunrise.secondary-onMain',
  'sunrise.tertiary-main',
  'sunrise.tertiary-active',
  'sunrise.tertiary-onMain',
  'sunrise.quaternary-onMain',
  'sunrise.quaternary-active',
  'sunrise.quaternary-main',
  'sunrise.quinary-onMain',
  'sunrise.quinary-active',
  'sunrise.quinary-main',
  'sunrise.info-main',
  'sunrise.info-onMain',
  'sunrise.success-onMain',
  'sunrise.success-main',
  'sunrise.success-dark',
  'sunrise.error-main',
  'sunrise.error-onMain',
  'sunset.grey-100',
  'sunset.grey-300',
  'sunset.grey-500',
  'sunset.grey-700',
  'sunset.grey-900',
  'sunset.hover',
] as const;

// Create a type that represents the values of the array
type QuickColorOption = (typeof QuickColorOptions)[number];

// Update the ☝️ array if you add ir change the SunriseColorOverrides options
interface SunriseColorOverrides extends Record<QuickColorOption, true> {}

declare module '@mui/material/Icon' {
  // NOTE: removing eslint below because line will reformat to type which we dont want
  // we are intentionally extending the type
  // eslint-disable-next-line
  interface IconPropsColorOverrides extends SunriseColorOverrides {}
}

declare module '@mui/material/Checkbox' {
  // NOTE: removing eslint below because line will reformat to type which we dont want
  // we are intentionally extending the type
  // eslint-disable-next-line
  interface CheckboxPropsColorOverrides extends SunriseColorOverrides {}
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xm: true;
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'] & Partial<PaletteColorChannel>;
    quaternary?: PaletteOptions['primary'] & Partial<PaletteColorChannel>;
    quinary?: PaletteOptions['primary'] & Partial<PaletteColorChannel>;
    ['sunrise.text-primary']?: PaletteOptions['primary'] &
      Partial<PaletteColorChannel>;
    ['sunrise.text-secondary']?: PaletteOptions['primary'] &
      Partial<PaletteColorChannel>;
  }
  interface Palette {
    tertiary: PaletteOptions['primary'] & Partial<PaletteColorChannel>;
    quaternary: PaletteOptions['primary'] & Partial<PaletteColorChannel>;
    quinary: PaletteOptions['primary'] & Partial<PaletteColorChannel>;
    ['sunrise.text-primary']: PaletteOptions['primary'] &
      Partial<PaletteColorChannel>;
    ['sunrise.text-secondary']: PaletteOptions['primary'] &
      Partial<PaletteColorChannel>;
  }
  interface SunriseColorPalette {
    main?: string;
    onMain?: string;
    active?: string;
    onActive?: string;
    focus?: string;
    onFocus?: string;
    focusBorder?: string;
    disabled?: string;
    onDisabled?: string;
    dark?: string;
    onDark?: string;
    light?: string;
    onLight?: string;
    surface?: string;
    onSurface?: string;
    error?: string;
  }

  interface SunriseTheme {
    text: {
      primary: string;
      secondary: string;
      tertiary: string;
      disabled: string;
    };
    primary: SunriseColorPalette;
    secondary: SunriseColorPalette;
    tertiary: SunriseColorPalette;
    quaternary: SunriseColorPalette;
    quinary: SunriseColorPalette;
    error: SunriseColorPalette;
    info: SunriseColorPalette;
    success: SunriseColorPalette;
    border: string;
    borderBright: string;
    grey700: string;
    hover: string;
    grey: {
      '100': string;
      '300': string;
      '500': string;
      '700': string;
      '900': string;
    };
    greyBlack: string;
    surface: {
      main: string;
      dark: string;
      light: string;
      onSurface: string;
      onSurfaceVariant: string;
    };
  }

  interface Theme {
    sunrise: {
      light: SunriseTheme;
      dark: SunriseTheme;
    };
  }

  interface SunriseThemeOptions {
    text?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
      disabled?: string;
    };
    primary?: SunriseColorPalette;
    secondary?: SunriseColorPalette;
    tertiary?: SunriseColorPalette;
    quaternary?: SunriseColorPalette;
    quinary?: SunriseColorPalette;
    info?: SunriseColorPalette;
    success?: SunriseColorPalette;
    error?: SunriseColorPalette;
    border?: string;
    borderBright?: string;
    hover?: string;
    grey?: {
      '100': string;
      '300': string;
      '500': string;
      '700': string;
      '900': string;
    };
    greyBlack?: string;
    surface?: {
      main?: string;
      onMain?: string;
    };
    surfaceBright?: {
      main?: string;
      onMain?: string;
    };
  }

  interface ThemeOptions {
    sunrise?: {
      light?: SunriseThemeOptions;
      dark?: SunriseThemeOptions;
    };
  }

  interface TypographyVariants {
    ['body1.cropped']: React.CSSProperties;
    ['display.hero']: React.CSSProperties;
    ['display.large']: React.CSSProperties;
    ['display.medium']: React.CSSProperties;
    ['display.small']: React.CSSProperties;
    ['headline.large']: React.CSSProperties;
    ['headline.medium']: React.CSSProperties;
    ['headline.small']: React.CSSProperties;
    ['title.large']: React.CSSProperties;
    ['title.medium']: React.CSSProperties;
    ['title.small']: React.CSSProperties;
    ['label.large']: React.CSSProperties;
    ['label.medium']: React.CSSProperties;
    ['label.small']: React.CSSProperties;
    ['body.large']: React.CSSProperties;
    ['body.medium']: React.CSSProperties;
    ['body.small']: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    ['body1.cropped']?: React.CSSProperties;
    ['display.hero']?: React.CSSProperties;
    ['display.large']?: React.CSSProperties;
    ['display.medium']?: React.CSSProperties;
    ['display.small']?: React.CSSProperties;
    ['headline.large']?: React.CSSProperties;
    ['headline.medium']?: React.CSSProperties;
    ['headline.small']?: React.CSSProperties;
    ['title.large']?: React.CSSProperties;
    ['title.medium']?: React.CSSProperties;
    ['title.small']?: React.CSSProperties;
    ['label.large']?: React.CSSProperties;
    ['label.medium']?: React.CSSProperties;
    ['label.small']?: React.CSSProperties;
    ['body.large']?: React.CSSProperties;
    ['body.medium']?: React.CSSProperties;
    ['body.small']?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ['body1.cropped']: true;
    ['display.hero']: true;
    ['display.large']: true;
    ['display.medium']: true;
    ['display.small']: true;
    ['headline.large']: true;
    ['headline.medium']: true;
    ['headline.small']: true;
    ['title.large']: true;
    ['title.medium']: true;
    ['title.small']: true;
    ['label.large']: true;
    ['label.medium']: true;
    ['label.small']: true;
    ['body.large']: true;
    ['body.medium']: true;
    ['body.small']: true;
  }
  // eslint-disable-next-line
  interface TypographyPropsColorOverrides extends SunriseColorOverrides {}
}
// ----------

// ----------
// Theme setup
const FONT_DISPLAY = `FamilyMedium, Times New Roman, Georgia, serif`;
const FONT_TITLE = `SöhneKräftig, Helvetica Neue, ui-sans-serif, system-ui`;
const FONT = `SöhneBuch, Helvetica Neue, ui-sans-serif, system-ui`;

const fontFamilyDisplay = {
  fontFamily: FONT_DISPLAY,
  fontWeight: 500,
  fontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  WebkitFontSmoothing: 'antialiased',
};
const fontFamilyTitle = {
  fontFamily: FONT_TITLE,
  fontWeight: 500,
  fontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  WebkitFontSmoothing: 'antialiased',
};
const fontFamily = {
  fontFamily: FONT,
  fontWeight: 400,
  fontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  WebkitFontSmoothing: 'antialiased',
};
type TypeProps = {
  fontFamily: string;
  fontSize: string;
  fontSmooth?: string;
  lineHeight: string;
  color?: string;
  paddingTop?: string;
  paddingBottom?: string;
};

export const TypographySunrise: {
  [key: string]: TypeProps;
} = {
  // unused
  'display.hero': {
    ...fontFamilyDisplay,
    fontSize: '6.25rem',
    lineHeight: '6.75rem',
  },
  // unused
  'display.large': {
    ...fontFamilyDisplay,
    fontSize: '4rem',
    lineHeight: '4.5rem',
  },
  // h1
  'display.medium': {
    ...fontFamilyDisplay,
    fontSize: '3rem',
    lineHeight: '3.75rem',
  },
  // h2
  'display.small': {
    ...fontFamilyDisplay,
    fontSize: '2rem',
    lineHeight: '2rem',
    paddingBottom: '1.25rem',
  },
  // unused
  'headline.large': {
    ...fontFamilyTitle,
    fontSize: '4rem',
    lineHeight: '4.75rem',
  },
  // unused
  'headline.medium': {
    ...fontFamilyTitle,
    fontSize: '3rem',
    lineHeight: '3.5rem',
  },
  // unused
  'headline.small': {
    ...fontFamilyTitle,
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
  // h3
  'title.large': {
    ...fontFamilyTitle,
    fontSize: '1.5em',
    lineHeight: '2rem',
  },
  // h4
  'title.medium': {
    ...fontFamilyTitle,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
  // h5, overline, h6
  'title.small': {
    ...fontFamilyTitle,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  'label.large': {
    ...fontFamilyTitle,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  // buttons
  'label.medium': {
    ...fontFamilyTitle,
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
  // unused
  'label.small': {
    ...fontFamilyTitle,
    fontSize: '0.625rem',
    lineHeight: '1rem',
  },
  // body1
  'body.large': {
    ...fontFamily,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  // body2, subtitle1
  'body.medium': {
    ...fontFamily,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  // subtitle2, caption
  'body.small': {
    ...fontFamily,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
  },
};

export const TypographyTheme: {
  [key: string]: {
    mapping: string;
    styles: TypeProps;
  };
} = {
  h1: {
    mapping: 'display.medium',
    styles: TypographySunrise['display.medium'],
  },
  h2: {
    mapping: 'display.small',
    styles: TypographySunrise['display.small'],
  },
  h3: {
    mapping: 'title.large',
    styles: TypographySunrise['title.large'],
  },
  h4: {
    mapping: 'title.medium',
    styles: TypographySunrise['title.medium'],
  },
  h5: {
    mapping: 'title.small',
    styles: TypographySunrise['title.small'],
  },
  h6: {
    mapping: 'title.small',
    styles: TypographySunrise['title.small'],
  },
  overline: {
    mapping: 'title.small',
    styles: TypographySunrise['title.small'],
  },
  body1: {
    mapping: 'body.large',
    styles: TypographySunrise['body.large'],
  },
  body2: {
    mapping: 'body.medium',
    styles: TypographySunrise['body.medium'],
  },
  subtitle1: {
    mapping: 'body.medium',
    styles: TypographySunrise['body.medium'],
  },
  subtitle2: {
    mapping: 'body.small',
    styles: TypographySunrise['body.small'],
  },
  caption: {
    mapping: 'body.small',
    styles: TypographySunrise['body.small'],
  },
};

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        secondary: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        tertiary: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        quaternary: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        quinary: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        ['sunrise.text-primary']: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        ['sunrise.text-secondary']: {
          main: '#FFC107',
          dark: '#FFA000',
        },
        Alert: {
          successIconColor: 'var(--mui-sunrise-light-success-onDark)',
          errorIconColor: 'var(--mui-sunrise-light-error-onDark)',
          infoIconColor: 'var(--mui-sunrise-light-info-onDark)',
        },
      },
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        icon: {
          color: 'white',
        },
        standardSuccess: {
          backgroundColor: 'var(--mui-sunrise-light-success-dark)',
          color: 'var(--mui-sunrise-light-success-onDark)',
        },
        standardError: {
          backgroundColor: 'var(--mui-sunrise-light-error-dark)',
          color: 'var(--mui-sunrise-light-error-onDark)',
        },
        standardInfo: {
          backgroundColor: 'var(--mui-sunrise-light-info-dark)',
          color: 'var(--mui-sunrise-light-info-onDark)',
        },
        root: {
          width: '100%',
        },
      },
    },
    // --mui-palette-Alert-errorIconColor)
    MuiBadge: {
      styleOverrides: {
        badge: {
          zIndex: 'auto',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'in-progress' },
          style: {
            ...TypographySunrise['label.medium'],
            color: `var(--mui-sunrise-light-success-onMain) !important`,
            backgroundColor: `var(--mui-sunrise-light-success-main)`,
            border: `2px solid var(--mui-sunrise-light-success-main)`,
            '.MuiButton-startIcon': {
              color: `var(--mui-sunrise-light-success-onMain) !important`,
            },
            ':disabled': {
              color: `var(--mui-sunrise-light-success-onMain) !important`,
              backgroundColor: `var(--mui-sunrise-light-success-main)`,
              border: `2px solid var(--mui-sunrise-light-success-main)`,
            },
          },
        },
        {
          props: { variant: 'icon' },
          style: {
            minWidth: 'auto',
            padding: '4px 8px',
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            ...TypographySunrise['label.medium'],
            color: `var(--mui-sunrise-light-error-onMain) !important`,
            backgroundColor: `var(--mui-sunrise-light-error-main)`,
            border: `2px solid var(--mui-sunrise-light-error-main)`,
            ':hover': {
              color: `var(--mui-sunrise-light-error-onMain) !important`,
              backgroundColor: `var(--mui-sunrise-light-error-dark)`,
              border: `2px solid var(--mui-sunrise-light-error-dark)`,
            },
            ':disabled': {
              color: `var(--mui-sunrise-light-error-onMain) !important`,
              backgroundColor: `var(--mui-sunrise-light-error-main)`,
              border: `2px solid var(--mui-sunrise-light-error-main)`,
            },
            ':focus': {
              color: `var(--mui-sunrise-light-error-onMain) !important`,
              backgroundColor: `var(--mui-sunrise-light-error-dark)`,
              border: `2px solid var(--mui-sunrise-light-error-dark)`,
            },
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...TypographySunrise['label.medium'],
          paddingTop: '0.625rem', // 12px
          paddingBottom: '0.625rem', // 12px
          paddingLeft: '1.4rem', // 22px
          paddingRight: '1.4rem', // 22px
          backgroundColor: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-main)`,
          color: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-onMain)`,
          border: `2px solid var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-main)`,
          ':hover': {
            backgroundColor: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-active)`,
            color: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-onActive)`,
            border: `2px solid var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-active)`,
          },
          ':focus': {
            backgroundColor: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-focus)`,
            color: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-onFocus)`,
            border: `2px solid var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-focusBorder)`,
          },
          ':disabled': {
            backgroundColor: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-disabled)`,
            color: alpha(
              // @ts-ignore
              theme.sunrise[theme.palette.mode][ownerState.color].onFocus,
              0.38,
            ),
            border: `2px solid var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-disabled)`,
          },
        }),
        iconSizeMedium: {
          '.MuiTypography-root': {
            fontSize: 12,
            lineHeight: '1rem', // 16px
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // Disable ripple globally
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          ':hover': {
            padding: '4px 8px',
          },
          marginRight: 0,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === 'standard'
            ? {
                color: `var(--mui-sunrise-${theme.palette.mode}-surfaceBright-onMain)`,
                backgroundColor: `var(--mui-sunrise-${theme.palette.mode}-surfaceBright-main)`,
                '.MuiCardHeader-root': {
                  padding: '20px',
                  paddingBottom: 0,
                },
                '.MuiCardContent-root': {
                  padding: '20px',
                },
                '.MuiCardActions-root': {
                  padding: '20px',
                  paddingTop: 0,
                },
              }
            : {}),

          ...(ownerState.variant === 'inner'
            ? {
                color: `var(--mui-sunrise-${theme.palette.mode}-surfaceBright-onMain)`,
                backgroundColor: `var(--mui-sunrise-${theme.palette.mode}-tertiary-main)`,
                '.MuiCardHeader-root': {
                  padding: '16px',
                  paddingBottom: 0,
                },
                '.MuiCardContent-root': {
                  padding: '16px',
                },
                '.MuiCardActions-root': {
                  padding: '16px',
                  paddingTop: 0,
                },
              }
            : {}),
        }),
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            borderRadius: '1em',
            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.12)',
          },
        },
        {
          props: { variant: 'inner' },
          style: {
            borderRadius: '1em',
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'status' },
          style: {
            backgroundColor: 'transparent',
          },
        },
      ],
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (!ownerState.color) {
            return {
              color: `var(--mui-sunrise-${theme.palette.mode}-primary-main)`,
            };
          }
          if (ownerState.color === 'inherit') {
            return { color: 'inherit' };
          }
          return {
            color: `var(--mui-sunrise-${theme.palette.mode}-${ownerState.color}-main)`,
          };
        },
      },
    },

    MuiDivider: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            borderColor: 'var(--mui-sunrise-light-borderBright)',
            marginTop: '2rem',
          },
        },
        {
          props: { variant: 'inner' },
          style: {
            borderColor: 'var(--mui-sunrise-light-border)',
            marginTop: '2rem',
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'var(--mui-sunrise-light-text-primary)',
          textDecorationColor: 'var(--mui-sunrise-light-text-primary)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (!ownerState.color) {
            return {
              color: `var(--mui-sunrise-${theme.palette.mode}-text-primary)`,
            };
          }
          if ((ownerState.color as string).includes('sunrise')) {
            const color = (ownerState.color as string).split('.')[1];
            return {
              color: `var(--mui-sunrise-${theme.palette.mode}-${color})`,
            };
          }
          return {};
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (!ownerState.color) {
            return {
              color: `var(--mui-sunrise-${theme.palette.mode}-text-primary)`,
            };
          }
          if ((ownerState.color as string).includes('sunrise')) {
            const color = (ownerState.color as string).split('.')[1];
            return {
              color: `var(--mui-sunrise-${theme.palette.mode}-${color})`,
            };
          }
          return {};
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: '8px',
          '& .MuiOutlinedInput-input': {
            padding: '8px 8px 8px 8px', // Set padding to 8px for all sides
          },
          border: `1px solid var(--mui-sunrise-${theme.palette.mode}-${
            (ownerState?.formControl as { color?: string })?.color ?? 'primary'
          }-main)`,

          '&:hover': {
            borderColor: `var(--mui-sunrise-${theme.palette.mode}-${
              (ownerState?.formControl as { color?: string })?.color ??
              'primary'
            }-active)`,
          },
          '&:focused': {
            borderColor: `var(--mui-sunrise-${theme.palette.mode}-${
              (ownerState?.formControl as { color?: string })?.color ??
              'primary'
            }-focus)`,
          },
          // needed because tailwind is still on the page
          '.MuiInputBase-input': {
            border: 'none',
            boxShadow: 'none',
            padding: '8px 8px 8px 8px',
          },
          '&.Mui-error': {
            borderColor: `var(--mui-sunrise-${theme.palette.mode}-error-main)`,
          },
          fieldset: {
            border: 'none',
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '.MuiInputBase-root': {
            backgroundColor: 'white',
            padding: '0 0 0 0',
            // Override webkit autofill styles
            '& .MuiOutlinedInput-input:-webkit-autofill': {
              '-webkit-box-shadow': '0 0 0 100px #E8F0FE inset',
              '-webkit-text-fill-color': 'inherit',
            },
          },
          '.MuiInputBase-inputMultiline': {
            padding: '16px 14px',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          transform: 'none',
          position: 'relative',
          transition: 'none',
          top: 'auto',
          left: 'auto',
          paddingLeft: '8px',
          paddingBottom: theme.spacing(0.5),
          color: theme.sunrise[theme.palette.mode].text.secondary,
          fontSize: '14px',
          '&.Mui-focused': {
            color: theme.sunrise[theme.palette.mode].text.secondary,
          },
          zIndex: 'auto',
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          transform: 'none',
          position: 'relative',
          transition: 'none',
          top: 'auto',
          left: 'auto',
          paddingLeft: '8px',
          paddingBottom: theme.spacing(0.5),
          color: theme.sunrise[theme.palette.mode].text.secondary,
          fontSize: '14px',
          '&.Mui-focused': {
            color: theme.sunrise[theme.palette.mode].text.secondary,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPickersPopper-paper': {
            borderRadius: '8px', // Border radius for DatePicker Popper component
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          },
          '&.MuiPopover-paper': {
            borderRadius: '8px', // Border radius for Select dropdown menu
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '8px',
          backgroundColor: 'white',
          borderColor: `var(--mui-sunrise-${theme.palette.mode}-secondary-main)`,
          '&:hover': {
            borderColor: `var(--mui-sunrise-${theme.palette.mode}-secondary-active)`,
          },
          '&:focused': {
            borderColor: `var(--mui-sunrise-${theme.palette.mode}-secondary-focus)`,
          },
        }),
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          let colorStyle = {};
          if (!ownerState.color) {
            colorStyle = {
              color: `var(--mui-sunrise-${theme.palette.mode}-text-primary)`,
            };
          } else if ((ownerState.color as string).includes('sunrise')) {
            const color = (ownerState.color as string).split('.')[1];
            colorStyle = {
              color: `var(--mui-sunrise-${theme.palette.mode}-${color})`,
            };
          }

          return {
            ...colorStyle,
            '&.MuiDayCalendar-weekDayLabel': {
              fontSize: '0.8rem', // Customize the font size for the day of week labels
            },
          };
        },
      },
    },
  },
  typography: {
    fontFamily: FONT,
    fontSize: 16,
    button: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1rem', // 16px

      fontWeight: 500,
      textTransform: 'none',
    },
    h1: TypographyTheme.h1.styles,
    h2: TypographyTheme.h2.styles,
    h3: TypographyTheme.h3.styles,
    h4: TypographyTheme.h4.styles,
    h5: TypographyTheme.h5.styles,
    h6: TypographyTheme.h6.styles,
    body1: TypographyTheme.body1.styles,
    body2: TypographyTheme.body2.styles,
    subtitle1: TypographyTheme.subtitle1.styles,
    subtitle2: TypographyTheme.subtitle2.styles,
    caption: TypographyTheme.caption.styles,
    overline: TypographyTheme.overline.styles,
    'body1.cropped': {
      ...TypographyTheme.body1.styles,
      display: '-webkit-box',
      WebkitLineClamp: 4,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    'display.hero': TypographySunrise['display.hero'],
    'display.large': TypographySunrise['display.large'],
    'display.medium': TypographySunrise['display.medium'],
    'display.small': TypographySunrise['display.small'],
    'headline.large': TypographySunrise['headline.large'],
    'headline.medium': TypographySunrise['headline.medium'],
    'headline.small': TypographySunrise['headline.small'],
    'label.large': TypographySunrise['label.large'],
    'label.medium': TypographySunrise['label.medium'],
    'label.small': TypographySunrise['label.small'],
    'title.large': TypographySunrise['title.large'],
    'title.medium': TypographySunrise['title.medium'],
    'title.small': TypographySunrise['title.small'],
    'body.large': TypographySunrise['body.large'],
    'body.medium': TypographySunrise['body.medium'],
    'body.small': TypographySunrise['body.small'],
  },
  sunrise: {
    light: {
      text: {
        primary: '#171515',
        secondary: '#6E6C66',
        tertiary: '#FFFFFF',
        disabled: '#A1A09B',
      },
      // border-1
      border: '#F2E2C4',
      borderBright: '#F9F2EC',
      greyBlack: '#171515',
      primary: {
        main: '#FFC220',
        onMain: '#171515',
        active: '#FBB805',
        onActive: '#171515',
        focus: '#FFC220',
        onFocus: '#171515',
        focusBorder: '#F2A612',
        disabled: '#F6F6F6',
        onDisabled: '#A1A09B',
      },
      secondary: {
        main: '#F2E2C4',
        onMain: '#171515',
        active: '#F5DAA7',
        onActive: '#171515',
        focus: '#F5DAA7',
        onFocus: '#171515',
        focusBorder: '#F2E2C4',
        disabled: '#F6F6F6',
        onDisabled: '#A1A09B',
      },
      tertiary: {
        main: '#F9F2EC',
        onMain: '#171515',
        active: '#FFEFD0',
        onActive: '#171515',
        focus: '#F9F2EC',
        onFocus: '#171515',
        focusBorder: '#FFEFD0',
        disabled: '#F6F6F6',
        onDisabled: '#171515',
      },
      quaternary: {
        main: 'transparent',
        onMain: '#171515',
        active: '#FFEFD0',
        onActive: '#171515',
        focus: 'transparent',
        onFocus: '#171515',
        focusBorder: '#F2A612',
        disabled: '#F6F6F6',
        onDisabled: '#171515',
      },
      quinary: {
        main: 'transparent',
        onMain: '#6E6C66',
        active: '#FFEFD0',
        onActive: '#171515',
        focus: 'transparent',
        onFocus: '#171515',
        focusBorder: '#F2A612',
        disabled: '#F6F6F6',
        onDisabled: '#171515',
      },
      info: {
        main: '#018DFF',
        onMain: '#FFFFFF',
        dark: '#0059A1',
        onDark: '#FFFFFF',
        light: '#7DC4FF',
        onLight: '#171515',
        surface: '#B1DBFE',
        onSurface: '#0059A1',
      },
      success: {
        main: '#52B542',
        onMain: '#FFFFFF',
        dark: '#156608',
        onDark: '#FFFFFF',
        active: '#156608',
        onActive: '#FFFFFF',
        focus: '#156608',
        onFocus: '#FFFFFF',
        focusBorder: 'transparent',
        light: '#44C67F',
        onLight: '#171515',
        surface: '#E0F1DA',
        onSurface: '#156608',
      },
      error: {
        main: '#F93D02',
        onMain: '#FFFFFF',
        dark: '#C12E00',
        active: '#C12E00',
        onDark: '#FFFFFF',
        light: '#F27969',
        onLight: '#171515',
        surface: '#F9C1B9',
        onSurface: '#C12E00',
      },
      surface: {
        main: '#F9F2EC',
        onMain: '#171515',
      },
      surfaceBright: {
        main: '#FFFFFF',
        onMain: '#171515',
      },
      hover: '#FAFAFA',
      grey: {
        100: '#6E6C66',
        300: '#7D7C7B',
        500: '#A1A09B',
        700: '#D5D5D7',
        900: '#F6F6F6',
      },
    },
  },
  shape: {
    borderRadius: 24,
  },
});

// Theres no specific dark theme for now, this is just a copy of the light theme
theme.sunrise.dark = theme.sunrise.light;
